<template>
  <v-container class="mt-4">
    <v-row v-if="links && links.length > 0">
      <v-col cols="12" md="4" v-for="link in links" :key="link._id">
        <v-card class="mx-auto card">
          <v-list-item three-line class="">
            <v-list-item-content class="pb-0">
              <div class="text-overline mb-4"></div>
              <v-list-item-title class="text-h5 mb-1">
                <v-btn v-if="link.productURL" :href="link.productURL" class="" color="#494569" icon depressed>
                  <v-icon class="icon">mdi-vector-link</v-icon>
                </v-btn>
                {{ link.description || "Description del producto" }}
              </v-list-item-title>
              <div class="">
                <v-card-subtitle class="mt-3 ml-3 p-0 font-semibold">
                  Monto: {{ link.amount | money(merchant.currency) }}
                </v-card-subtitle>
                <v-card-subtitle class="ml-3 p-0 font-semibold">
                  Usage: {{ link.usage || 0 }} /
                  {{ link.usageLimit ? link.usageLimit : "∞" }}
                </v-card-subtitle>
                <v-card-subtitle
                    class="ml-3 p-0 font-semibold"
                    v-if="link.expiresIn"
                >
                  Expira en: {{ new Date(link.expiresIn).toDateString() }}
                </v-card-subtitle>
                <v-card-subtitle class="ml-3 p-0 font-semibold color">
                  Comision: 5.95%
                </v-card-subtitle>
                <v-card-subtitle class="ml-3 p-0 font-semibold color">
                  Usted Recibira:
                  {{ (link.amount - link.amount * (5.95 / 100)) | money(merchant.currency) }}
                </v-card-subtitle>
              </div>
            </v-list-item-content>

            <v-list-item-avatar tile size="110">
              <v-img
                  class="img"
                  :src="
                  link.image
                    ? link.image
                    : merchant.logo
                    ? merchant.logo
                    : 'https://app.yoyo.do/assets/images/Yoyo.png'
                "
              ></v-img>
            </v-list-item-avatar>
          </v-list-item>

          <v-card-actions class="pt-0 mb-0">
            <v-btn class="ml-2 mt-5" color="#494569" icon depressed>
              <v-icon
                  v-clipboard:copy="link.url"
                  v-clipboard:success="onCopy"
                  class="p-2 icon"
              >mdi-content-copy
              </v-icon
              >
            </v-btn>
            <v-btn
                target="_blank"
                :href="link.url"
                class="ml-2 mt-5"
                color="#494569"
                icon
                depressed
            >
              <v-icon class="p-2 icon">mdi-web</v-icon>
            </v-btn>

            <v-btn
                @click="openDialog(true, link)"
                class="ml-2 mt-5"
                color="#494569"
                icon
                depressed
            >
              <v-icon class="p-2 icon">mdi-pencil</v-icon>
            </v-btn>
            <v-btn
                @click="onDelete(link)"
                class="ml-2 mt-5"
                color="#494569"
                icon
                depressed
            >
              <v-icon class="p-2 icon">mdi-delete-outline</v-icon>
            </v-btn>

            <div class="flex justify-end chip">
              <v-chip
                  v-if="link.status !== 'PENDING' || link.status !== 'EXPIRED'"
                  class="ma-2"
                  color="success"
                  outlined
              >
                <v-icon left> mdi-check</v-icon>
                Active
              </v-chip>
              <v-chip
                  v-else-if="link.status === 'EXPIRED'"
                  class="ma-2"
                  color="danger"
                  outlined
                  pill
              >
                Expired
                <v-icon right> mdi-clock-alert-outline</v-icon>
              </v-chip>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <!-- <div> -->
    <h1
        style="margin-top: 100px"
        class="p-5 text-center text-bold text-xl"
        v-else
    >
      Aqui podras visualizar tus productos o servicios. Comienza creando uno.
    </h1>
    <!-- </div> -->
    <v-btn
        @click="dialog = !dialog"
        elevation="2"
        class="mb-10 btn"
        fab
        absolute
        bottom
        right
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <link-form
        @float:created="onFloatCreated"
        @added="onAdded"
        @edited="onEdited"
        @close:form="onFormClose"
        :type="'PRODUCT'"
        :link="dialogLink"
        :editMode="dialogEditMode"
        :show="dialog"
        :merchant="merchant"
    />
    <alert ref="alert"/>
  </v-container>
</template>
<script lang="js">
import { createFloat, renewSession } from "@/utils";
import LinkForm from "@/components/LinkForm.vue";
import Alert from "@/components/Alert.vue";
import { AUTH_HEADER } from "@/config/constants";

export default {
  data: () => ({
    show: false,
    dialog: false,
    links: [],
    alert: {
      show: false,
      onClose: () => {},
      title: "",
      text: "",
      buttons: [],
    },
    dialogLink: {},
    dialogEditMode: false,
  }),
  components: {
    LinkForm,
    Alert,
  },
  props: {
    eventLink: {},
    merchant: {},
  },
  watch: {
    eventLink() {
      if (this.eventLink) this.onAdded(this.eventLink);
      // else if (this.eventLink && this.eventLink.eventType === "edited")
      //   this.onEdited(this.eventLink);
    },
  },
  async mounted() {
    const resp = await fetch(
      `${process.env.VUE_APP_API_URL}/transactions/product-links`,
      {
        headers: {
          [AUTH_HEADER]: localStorage.getItem("session"),
        },
      }
    );

    const { ok, data, errors, errorMessage } = await resp.json();
    if (ok) {
      this.links = data;
      renewSession(resp);
    }
    if (!ok) {
      if (errorMessage) this.error = errorMessage;
      else if (errors) {
        this.error = errors[0].msg;
      } else {
        this.error = resp.statusText;
      }
    }
  },
  methods: {
    onCopy() {
      this.$emit(
        "float:created",
        createFloat({ text: "Link Copiado!", duration: 2000 })
      );
    },
    onFloatCreated(f) {
      this.$emit("float:created", f);
    },
    onFormClose() {
      this.dialogLink = {};
      this.dialogEditMode = false;
      this.dialog = false;
    },
    openDialog(editMode, l) {
      this.dialog = true;
      if (editMode) {
        this.dialogEditMode = true;
        this.dialogLink = l;
      } else {
        this.dialogEditMode = false;
      }
    },
    onAdded(data) {
      this.links = [data, ...this.links];
    },
    onEdited(data) {
      const i = this.links.findIndex((x) => x._id === data._id);
      this.links[i] = data;
    },
    async onDelete(l) {
      const agree = await this.$refs.alert.showAlert({
        title: "Eliminar",
        text: "Desea eliminar el producto?",
        buttons: [{ text: "No", color: "red", role: "cancel" }, { text: "Si" }],
      });

      if (!agree) return;

      const resp = await fetch(
        `${process.env.VUE_APP_API_URL}/products/${l._id}`,
        {
          method: "DELETE",
          headers: {
            [AUTH_HEADER]: localStorage.getItem("session"),
          },
        }
      );

      const { ok, data, errors, errorMessage } = await resp.json();
      if (ok) {
        this.links = this.links.filter((x) => x._id !== l._id);
        renewSession(resp);
      }
      if (!ok) {
        if (errorMessage) this.error = errorMessage;
        else if (errors) {
          this.error = errors[0].msg;
        } else {
          this.error = resp.statusText;
        }
      }
    },
  },
};

</script>

<style>
.img {
  border-radius: 7px !important;
}

.schip {
  width: 100% !important;
}

.color {
  color: gray !important;
}

.btn {
  background-color: white !important;
}
</style>
